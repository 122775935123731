<script>
export default {
	props: {
		data: Object,
	},

	cssVars: {
		selector: '.wall-categories',
	},
}
</script>

<template>
	<v-container v-if="data.categories && data.categories.length" :class="$bem('wall-categories')">
		<v-row :class="{ 'justify-center': $b.t }">
			<v-col cols="12" md="6" lg="4" sm="8" v-for="(item, i) in data.categories" :key="i">
				<v-card :class="$bem('__card')">
					<div
						class="d-flex"
						:class="{
							'flex-column text-center pt-4': $b.m,
							'justify-center columnGap': $b.t,
							'justify-space-between': $b.d,
						}"
					>
						<div v-if="$b.m">
							<v-avatar class="ma-3" size="200" tile>
								<Media imgClass="rounded" :src="item.img.fullUrl" cover></Media>
							</v-avatar>
						</div>
						<div
							:class="{ 'pb-3 align-center': $b.mt }"
							class="text-center d-flex flex-column justify-space-around align-start"
						>
							<v-card-title
								class="text-body-1 header primary--text text-uppercase justify-center"
								:class="{ 'py-2': $b.mt, 'py-0 mt-2': $b.d }"
								v-text="item.title"
							></v-card-title>
							<v-card-actions :class="{ 'btn-mobile': $b.m, 'py-0': $b.d }">
								<v-btn
									small
									tile
									depressed
									:to="
										$shop.getShopRoute({
											categories: item.dogCategory.parents
												.map((c) => c.urlName)
												.concat(item.dogCategory.urlName),
										})
									"
									class="cta"
								>
									<v-icon class="mr-2 secondary--text">mdi-dog</v-icon>
									Perros
								</v-btn>

								<v-btn
									class="cta"
									small
									tile
									depressed
									:to="
										$shop.getShopRoute({
											categories: item.catCategory.parents
												.map((c) => c.urlName)
												.concat(item.catCategory.urlName),
										})
									"
								>
									<v-icon class="mr-2 secondary--text">mdi-cat</v-icon>
									Gatos
								</v-btn>
							</v-card-actions>
						</div>
						<div v-if="$b.td">
							<v-avatar class="ma-3" size="100" tile>
								<Media imgClass="rounded-lg" :src="item.img.fullUrl" cover></Media>
							</v-avatar>
						</div>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped>
.columnGap {
	column-gap: 2rem;
}
</style>
