<script>
export default {
	props: ['currentZone', 'CartItem'],
	hookZone: ['deleteBtn'],
	lang: 'shop',
}
</script>

<template>
	<div class="pl-4">
		<Button text color="error" @click="CartItem.deleteItem" :loading="CartItem.deleteLoading">
			{{ 'Eliminar' | lang }}
		</Button>
	</div>
</template>
