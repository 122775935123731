var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CollectionEventIcon',{class:[
		_vm.currentZone === 'event-iconHorizontal'
			? 'product-card-hooks-horizontal__event-icon'
			: 'product-card-hooks-vertical__event-icon',
		_vm.positionFix,
		{
			'product-card-hooks-horizontal__event-icon--w25':
				_vm.ProductCard.hasLimitedTimeOffer && _vm.currentZone === 'event-iconHorizontal',
		} ],attrs:{"product":_vm.ProductCard.product,"imgWidth":_vm.currentZone === 'event-iconHorizontal' ? '30px' : null}})}
var staticRenderFns = []

export { render, staticRenderFns }