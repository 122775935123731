var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.categories && _vm.data.categories.length)?_c('v-container',{class:_vm.$bem('wall-categories')},[_c('v-row',{class:{ 'justify-center': _vm.$b.t }},_vm._l((_vm.data.categories),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6","lg":"4","sm":"8"}},[_c('v-card',{class:_vm.$bem('__card')},[_c('div',{staticClass:"d-flex",class:{
						'flex-column text-center pt-4': _vm.$b.m,
						'justify-center columnGap': _vm.$b.t,
						'justify-space-between': _vm.$b.d,
					}},[(_vm.$b.m)?_c('div',[_c('v-avatar',{staticClass:"ma-3",attrs:{"size":"200","tile":""}},[_c('Media',{attrs:{"imgClass":"rounded","src":item.img.fullUrl,"cover":""}})],1)],1):_vm._e(),_c('div',{staticClass:"text-center d-flex flex-column justify-space-around align-start",class:{ 'pb-3 align-center': _vm.$b.mt }},[_c('v-card-title',{staticClass:"text-body-1 header primary--text text-uppercase justify-center",class:{ 'py-2': _vm.$b.mt, 'py-0 mt-2': _vm.$b.d },domProps:{"textContent":_vm._s(item.title)}}),_c('v-card-actions',{class:{ 'btn-mobile': _vm.$b.m, 'py-0': _vm.$b.d }},[_c('v-btn',{staticClass:"cta",attrs:{"small":"","tile":"","depressed":"","to":_vm.$shop.getShopRoute({
										categories: item.dogCategory.parents
											.map(function (c) { return c.urlName; })
											.concat(item.dogCategory.urlName),
									})}},[_c('v-icon',{staticClass:"mr-2 secondary--text"},[_vm._v("mdi-dog")]),_vm._v(" Perros ")],1),_c('v-btn',{staticClass:"cta",attrs:{"small":"","tile":"","depressed":"","to":_vm.$shop.getShopRoute({
										categories: item.catCategory.parents
											.map(function (c) { return c.urlName; })
											.concat(item.catCategory.urlName),
									})}},[_c('v-icon',{staticClass:"mr-2 secondary--text"},[_vm._v("mdi-cat")]),_vm._v(" Gatos ")],1)],1)],1),(_vm.$b.td)?_c('div',[_c('v-avatar',{staticClass:"ma-3",attrs:{"size":"100","tile":""}},[_c('Media',{attrs:{"imgClass":"rounded-lg","src":item.img.fullUrl,"cover":""}})],1)],1):_vm._e()])])],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }