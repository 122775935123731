<script>
export default {
	props: ['ProductCard', 'currentZone'],
	hookZone: ['event-icon', 'event-iconHorizontal'],
	computed: {
		cardType() {
			return this.$srv('productCardType.name') || 'LeftAligned'
		},

		positionFix() {
			if (this.currentZone == 'event-icon') {
				switch (this.cardType) {
					case 'CenterAligned':
						return 'product-card-hooks-vertical__event-icon--center-aligned'

					case 'CenterAlignedWithBtn':
						return 'product-card-hooks-vertical__event-icon--center-aligned-with-btn'

					case 'LeftAligned':
						return 'product-card-hooks-vertical__event-icon--left-aligned'

					case 'LeftAlignedWithBtn':
						return 'product-card-hooks-vertical__event-icon--left-aligned-with-btn'

					case 'Shadow':
						return 'product-card-hooks-vertical__event-icon--shadow'

					default:
						return 'product-card-hooks-vertical__event-icon--shadow-with-btn'
				}
			} else {
				switch (this.cardType) {
					case 'CenterAligned':
						return 'product-card-hooks-horizontal__event-icon--center-aligned'

					case 'CenterAlignedWithBtn':
						return 'product-card-hooks-horizontal__event-icon--center-aligned-with-btn'

					case 'LeftAligned':
						return 'product-card-hooks-horizontal__event-icon--left-aligned'

					case 'LeftAlignedWithBtn':
						return 'product-card-hooks-horizontal__event-icon--left-aligned-with-btn'

					case 'Shadow':
						return 'product-card-hooks-horizontal__event-icon--shadow'

					default:
						return 'product-card-hooks-horizontal__event-icon--shadow-with-btn'
				}
			}
		},
	},
}
</script>

<template>
	<CollectionEventIcon
		:product="ProductCard.product"
		:class="[
			currentZone === 'event-iconHorizontal'
				? 'product-card-hooks-horizontal__event-icon'
				: 'product-card-hooks-vertical__event-icon',
			positionFix,
			{
				'product-card-hooks-horizontal__event-icon--w25':
					ProductCard.hasLimitedTimeOffer && currentZone === 'event-iconHorizontal',
			},
		]"
		:imgWidth="currentZone === 'event-iconHorizontal' ? '30px' : null"
	/>
</template>

<style scoped lang="scss">
.product-card-hooks-vertical {
	&__event-icon--center-aligned-with-btn,
	&__event-icon--center-aligned {
		position: absolute;
		top: 55%;
		right: 8px;
		z-index: 1;
		width: 40px;
	}

	&__event-icon--center-aligned-with-btn {
		top: 48%;
	}

	&__event-icon--left-aligned-with-btn,
	&__event-icon--left-aligned {
		position: absolute;
		bottom: -7px;
		left: 6px;
		width: 40px;
	}

	&__event-icon--shadow,
	&__event-icon--shadow-with-btn {
		position: absolute;
		bottom: 0;
		left: 6px;
		width: 40px;
		z-index: 1;
	}
}

.product-card-hooks-horizontal {
	&__event-icon {
		position: absolute;
		top: 3px;
		right: 6px;
		width: 30px;
		z-index: 2;

		&--w25 {
			width: 25px;
		}

		&--shadow,
		&--shadow-with-btn {
			width: 25px;
			top: unset;
			right: unset;
			bottom: 0;
			left: 6px;
		}

		&--center-aligned-with-btn,
		&--center-aligned {
			top: 7px;
			right: 8px;
		}

		&--left-aligned,
		&--left-aligned-with-btn {
			width: 25px;
			top: 7px;
			left: 6px;
			right: unset;
		}

		&--left-aligned-with-btn {
			top: 0;
			// right: -135%;
		}
	}
}
</style>
