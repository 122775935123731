<script>
export default {
	props: {
		data: Object,
	},
}
</script>

<template>
	<v-container v-if="data.categories && data.categories.length" :class="$bem('categories-board')">
		<v-row :class="{ 'justify-center': $b.t }">
			<v-col cols="12" md="6" lg="4" sm="8" v-for="(item, i) in data.categories" :key="i">
				<v-card :class="$bem('__card')">
					<div
						class="d-flex"
						:class="{
							'flex-column text-center pt-4': $b.m,
							'justify-center columnGap': $b.t,
							'justify-space-between': $b.d,
						}"
					>
						<div v-if="$b.m">
							<v-avatar class="ma-3" size="200" tile>
								<Media imgClass="rounded" :src="item.img" cover />
							</v-avatar>
						</div>
						<div
							:class="{ 'align-center': $b.mt, 'justify-evenly': $b.t, 'pb-3': $b.m }"
							class="text-center d-flex flex-column justify-space-around align-start"
						>
							<v-card-title
								class="header primary--text text-uppercase justify-center"
								:class="{ 'py-2 font-4': $b.mt, 'py-0 mt-2 text-body-1': $b.d }"
								v-text="item.title"
							></v-card-title>
							<v-card-actions :class="{ 'py-0': $b.d }">
								<v-btn
									:small="$b.d"
									:large="$b.mt"
									tile
									depressed
									:to="
										$shop.getShopRoute({
											categories: item.dogCategory.familyUrlNames,
										})
									"
									class="cta"
									:class="{ 'py-5 px-3 btn-text-mobile': $b.mt }"
								>
									<v-icon class="mr-2 secondary--text">mdi-dog</v-icon>
									Perros
								</v-btn>

								<v-btn
									class="cta"
									:small="$b.d"
									:large="$b.mt"
									tile
									depressed
									:to="
										$shop.getShopRoute({
											categories: item.catCategory.familyUrlNames,
										})
									"
									:class="{ 'py-5 px-3 btn-text-mobile': $b.mt }"
								>
									<v-icon class="mr-2 secondary--text">mdi-cat</v-icon>
									Gatos
								</v-btn>
							</v-card-actions>
						</div>
						<div v-if="$b.td">
							<v-avatar class="ma-3" size="100" tile>
								<Media imgClass="rounded-lg" :src="item.img.fullUrl" cover></Media>
							</v-avatar>
						</div>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped>
.columnGap {
	column-gap: 2rem;
}

.justify-evenly {
	justify-content: space-evenly !important;
}

::v-deep .btn-text-mobile {
	font-size: 1.1rem;
}
</style>
