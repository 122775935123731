<script>
export default {
	props: {
		data: Object,
	},

	cssVars: {
		selector: '.wall-brands',
	},
}
</script>

<template>
	<v-container :class="$bem('wall-brands')">
		<v-row class="justify-space-between mx-1" :class="$bem('__header')">
			<h2 class="header font-4 d-block">{{ data.title }}</h2>
			<v-btn :to="data.link" text small depressed class="px-0 header link--text">
				{{ data.textCta }}
			</v-btn>
		</v-row>
		<v-row class="justify-center mt-4">
			<v-col v-for="item of data.brands" :key="item.id" lg="2" md="3" sm="4" cols="6">
				<v-card
					tile
					rounded
					class="text-center pt-md-3 px-md-3 px-1 pt-1 rounded"
					:to="$shop.getShopRoute({ brand: item.brand.urlName })"
					:class="$bem('__brand-card')"
				>
					<Media :src="item.img" width="100%" heigth="100%" fill />
					<v-card-subtitle class="text-center">
						<div
							class="rounded font-weight-medium text-md-body-3 py-2 py-lg-0 discount"
							:class="$b({ sm: 'discount-tagMobile' })"
						>
							<span v-if="item.discountPct">Hasta {{ item.discountPct }}% OFF</span>
							<span v-else>Ver productos</span>
						</div>
					</v-card-subtitle>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped>
.discount-tagMobile {
	font-size: 0.9rem;
}
</style>
